import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Real1 from '../../../images/work/14_CARMEL_CRICHTON/PHOTOS/CR_01.jpg'
import Real2 from '../../../images/work/14_CARMEL_CRICHTON/PHOTOS/CR_02.jpg'
import Real1s from '../../../images/work/14_CARMEL_CRICHTON/PHOTOS/450X280/CR-SM_01.jpg'
import Real2s from '../../../images/work/14_CARMEL_CRICHTON/PHOTOS/450X280/CR-SM_02.jpg'
import Process1 from '../../../images/work/14_CARMEL_CRICHTON/PROCESS/CRI_01.jpg'
import Process2 from '../../../images/work/14_CARMEL_CRICHTON/PROCESS/CRI_02.jpg'
import Process3 from '../../../images/work/14_CARMEL_CRICHTON/PROCESS/CRI_03.jpg'
import Process1s from '../../../images/work/14_CARMEL_CRICHTON/PROCESS/450X280/CRI-SM_01.jpg'
import Process2s from '../../../images/work/14_CARMEL_CRICHTON/PROCESS/450X280/CRI-SM_02.jpg'
import Process3s from '../../../images/work/14_CARMEL_CRICHTON/PROCESS/450X280/CRI-SM_03.jpg'

const Residence14 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="CARMEL COTTAGE"
        residenceLocation="CARMEL-BY-THE-SEA, CALIFORNIA"
        infoList={['Remodel of existing 1,700 SF cottage.', 'Design - 2018', 'Construction - 2018', 'General Contractor: Wallis Construction, Inc', 'Structural Engineer: Structures Engineering, Inc']}
        testimonialList={[]}
        photos={[Real1, Real2]}
        photosSmall={[Real1s, Real2s]}
        processPhotos={[Process1, Process2, Process3]}
        processPhotosSmall={[Process1s, Process2s, Process3s]}
      />
    </Container>
  </Layout>
)

export default Residence14;
